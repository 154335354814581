<template>
  <!-- <div class="title">个人中心</div> -->
  <div class="my-wrapper">
    
    
    <div class="con-box">
	  <van-cell title="用户注册协议" @click="isShow.axy=true" icon="description"  />
	  <van-cell title="用户隐私协议" @click="isShow.bxy=true" icon="description"  />
	  <van-cell title="会诊服务协议" @click="isShow.dxy=true" icon="description"  />
    </div>
	
	<!-- 协议弹框 -->
	<van-popup
	  v-model:show="isShow.axy"
	  round
	  style="height: 80%"
	>
	  <div class="xytknr">
	    <protocola></protocola>
	    <div style="text-align: center; margin: 20px">
	      <van-button
	        @click="isShow.axy = false"
	        style="width: 80%; border-radius: 22px"
	        type="primary"
	        >确定</van-button
	      >
	    </div>
	  </div>
	</van-popup>
	<van-popup
	  v-model:show="isShow.bxy"
	  round
	  style="height: 80%"
	>
	  <div class="xytknr">
	    <protocolb></protocolb>
	    <div style="text-align: center; margin: 20px">
	      <van-button
	        @click="isShow.bxy = false"
	        style="width: 80%; border-radius: 22px"
	        type="primary"
	        >确定</van-button
	      >
	    </div>
	  </div>
	</van-popup>
	
	<van-popup
	  v-model:show="isShow.dxy"
	  round
	  style="height: 80%"
	>
	  <div class="xytknr">
	    <protocold></protocold>
	    <div style="text-align: center; margin: 20px">
	      <van-button
	        @click="isShow.dxy = false"
	        style="width: 80%; border-radius: 22px"
	        type="primary"
	        >确定</van-button
	      >
	    </div>
	  </div>
	</van-popup>
	
  </div>
</template>

<script>
import {
  Cell,
  Image,
  Dialog,
  Icon,
  Swipe,
  SwipeItem,
  Grid,
  GridItem,
  Button,
  Popup
} from "vant";
import protocola from "../components/protocol/protocola.vue";
import protocolb from "../components/protocol/protocolb.vue";
import protocold from "../components/protocol/protocold.vue";
export default {
  name: "myCenter",
  components: {
    [Cell.name]: Cell,
    [Image.name]: Image,
    [Dialog.name]: Dialog,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
	[Popup.name]:Popup,
	[Button.name]:Button,
	protocola,
	protocolb,
	protocold
  },
  data() {
    return {
	  isShow:{
		  axy:false,
		  bxy:false,
		  dxy:false,
	  },
    };
  },
  created() {
    
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.con-box{
	padding-top:20px;
}
.xytknr {
  width: 80vw;
  box-sizing: border-box;
  padding: 20px;
}
</style>